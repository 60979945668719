import React, { useEffect } from "react";
import { WindupChildren,Pace } from "windups";

import Aos from "aos";
import "aos/dist/aos.css";


const Home = () => {
 

  useEffect(function () {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <>
      {/* Banner  */}
      <div className="top-banner">
      <video id="videobg" autoPlay playsInline loop muted><source src="/assets/video.mp4" type="video/mp4" /></video>
      <section className="banner" id="home">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-8 col-md-10 z-1" data-aos="fade-up">
              <h3>BUILT FOR EVERYONE</h3>
              <img src="assets/linea.gif" className="w-100 position-relative" style={{left: "-11px"}} alt="" />
              <h1><span>LEADING CONCENTRATED</span> <br /> <span className="liquid">LIQUIDITY DEX</span></h1>
              {/* <div className="mt-4 pt-lg-4">
                <a href="https://linea.build/apps" target={"_blank"} rel="noreferrer">
              <img src="assets/launch-p.svg" alt="" /></a>
              </div> */}
              <p className="my-4 py-lg-4">
                <WindupChildren>
                <Pace ms={50}>
                Trade + Earn crypto on Base & Linea + Safely 
                </Pace>
                </WindupChildren>
              </p>
              <div className="d-flex gap-4">
                <a
                  href="https://v2.horizondex.io/"
                  target={"_blank"}
                  className="primary-btn"
                  rel="noreferrer"
                >
                  Enter DEX
                </a>
                <a
                  href="https://docs.horizondex.io/"
                  target={"_blank"}
                  className="primary-btn-outline"
                  rel="noreferrer"
                >
                  <span>Read Docs</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      </div>
      <div className="main-bg">
      <section className="how-it-work">
        <div className="container">
        <div className="r1" data-aos="fade-up">
          <div className="row justify-content-center align-items-end">
            <div className="col-md-8">
                <h2>BUILDING UPON A STRONG FOUNDATION : BASE & LINEA</h2>
              <div className="content-box">
              <p>
                HorizonDEX is building upon the latest and robust networks in the crypto market: BASE, supported by Coinbase, and Linea, supported by ConsenSys, the creator of MetaMask.
                <br /> <br />
                Base is the latest Ethereum Layer 2 (L2) network developed which has been developed by Coinbase using OP Stack.
                <br /> <br />
                Base is described by Coinbase as a “secure, low-cost, developer-friendly Ethereum L2 built to bring the next billion users to web3".
                <br /> <br />
              Linea represents the next generation of Layer 2 zkEVM, combining low fees, Ethereum's security, and full EVM compatibility.
              </p>
              </div>
            </div>
            <div className="col-md-4 col-10 mx-auto ps-lg-5 pb-md-5">
              <img src="assets/h1.png" className="w-100" alt="" />
            </div>
          </div>
    </div>
          <div className="r1 g2" data-aos="fade-up">
          <div className="row justify-content-center align-items-center">
                      <div className="col-md-4 order-md-0 order-1 pe-lg-5 pt-lg-5">
                        <img src="assets/h2.png" className="w-100" alt="" />
                      </div>
                      <div className="col-md-8">
                        <h2>EARN ATTRACTIVE YIELDS <br className="d-none d-lg-block" /> WITH Horizon DEX</h2>
                        <div className="content-box">
                        <p>
                          HorizonDEX is on a mission to become the primary liquidity hub for BASE and LINEA. <br /> <br />
                          To achieve this, we will implement incentive programs that
                          empower users to earn attractive yields on their favorite
                          tokens. <br /> <br />
                          Our goal is simple and powerful; to promote and foster the growth of BASE & LINEA and pave the way for mainstream adoption.
                        </p>
                        </div>
                      </div>
                    </div>
          </div>

         
          <div className="r1" data-aos="fade-up">

          <div className="row justify-content-center align-items-md-center">
            <div className="col-md-8">
              <h2>FIRST CONCENTRATED <br className="d-none d-lg-block" /> LIQUIDITY DEX</h2>
              <div className="content-box">
              <p>
              All current DEXs on BASE & LINEA are built on the standard Uniswap-V2 model. <br />
                <br />
                Driven by improved design, concentrated liquidity DEXs attract significantly more volume. <br /> <br />
                HorizonDEX will be the pioneer in providing concentrated liquidity on BASE and LINEA.
              </p>
              </div>
            </div>
            <div className="col-md-4 mx-auto">
              <img src="assets/h3.png" className="w-100" alt="" />
            </div>
          </div>
          </div>

          <div className="r1 g2" data-aos="fade-up">
          <div className="row justify-content-center align-items-center">
            <div className="col-md-3 col-9 mx-auto order-md-0 order-1">
              <img src="assets/h4.png" className="w-100" alt="" />
            </div>
            <div className="col-md-8 offset-lg-1">
              <h2>Horizon DEX: FOCUSING ON SECURITY FIRST</h2>
              <div className="content-box">
              <p>
              As the HorizonDEX team, we are committed to providing maximum security and transparency. <br /> <br />
                HorizonDEX has been successfully audited by three independent auditing firms, namely: Hacken, SolidProof, and DefiMoon. <br /><br />
                Our audit results are published in our docs.
              </p>
              </div>
            </div>
          </div>
          </div>

          

          <div className="r1" data-aos="fade-up">
          <div className="row justify-content-center align-items-center">
          <div className="col-md-8">
              <h2>HORIZON DEX: BOOSTING BASE & LINEA LIQUIDITY</h2>
              <div className="content-box">
              <p>
              HorizonDEX helps boost liquidity on BASE & LINEA<br /> <br />
              Most chains receive significant liquidity but struggle to retain users – a commonly overlooked fact that often leads to their downfall… <br /> <br />
              Both BASE and LINEA are backed by the best and have a strong, committed user base. Now, we just need to build up their liquidity.
              </p>
              </div>
            </div>
            <div className="col-md-3 col-10 mx-auto">
              <img src="assets/h6.png" className="w-100" alt="" />
            </div>
            
          </div>
          </div>
        </div>
      </section>

      <section className="pkg" id="advantages">
        <div className="container">
          <div className="row justify-content-center py-5 my-lg-5">
            <div className="col-md-11">
              <h2 className="mb-5">
                Advantages of <br className="d-lg-block d-none" />
                Concentrated Liquidity{" "}
              </h2>

              <div className="pkg-responsive">
                <div className="pkg-card">
                  <div className="head">Price Impact:</div>
                  <div className="head">Capital Efficiency:</div>
                  <div className="head">Flexibilty:</div>
                  <div className="head">Impermanent Loss:</div>
                  <div className="head border-0">Trading Volume:</div>

                  <div className="price-card">
                    <div className="v1-card">
                      <div className="v1-head">Uni V2</div>
                      <div className="head1">
                        <img src="assets/cross.svg" className="me-1" alt="" />{" "}
                        High
                      </div>
                      <div className="head1">
                        <img src="assets/cross.svg" className="me-1" alt="" />{" "}
                        Low
                      </div>
                      <div className="head1">
                        <img src="assets/cross.svg" className="me-1" alt="" />{" "}
                        Low
                      </div>
                      <div className="head1">
                        <img src="assets/cross.svg" className="me-1" alt="" />{" "}
                        High
                      </div>
                      <div className="head1 border-0">
                        <img src="assets/cross.svg" className="me-1" alt="" />{" "}
                        Low
                      </div>
                    </div>
                    <div className="v2-card">
                      <div className="v2-head">Concentrated Liquidity</div>
                      <div className="head1">
                        <img src="assets/check.svg" className="me-1" alt="" />{" "}
                        Low
                      </div>
                      <div className="head1">
                        <img src="assets/check.svg" className="me-1" alt="" />{" "}
                        High
                      </div>
                      <div className="head1">
                        <img src="assets/check.svg" className="me-1" alt="" />{" "}
                        High (e.g. active liquidity provision, limit orders,
                        etc.)
                      </div>
                      <div className="head1">
                        <img src="assets/check.svg" className="me-1" alt="" />{" "}
                        Able to mitigate via variable fees
                      </div>
                      <div className="head1 border-0">
                        <img src="assets/check.svg" className="me-1" alt="" />{" "}
                        High (attracts significantly more volume)
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <i>
                TL;DR: Concentrated liquidity is an innovative development
                within Automated Market <br className="d-lg-block d-none" />
                Makers, enabling Liquidity Providers to adopt more refined
                strategies, <br className="d-lg-block d-none" /> optimize
                returns and mitigate risks.
              </i>
            </div>
          </div>
        </div>
      </section>

            <section className="audit-partner">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-md-10">
                            <div className="partner-card r2 mb-0" data-aos="fade-up">
                              <h2>Audit Partners</h2>
                              <div className="row justify-content-evenly align-items-center">
                                <div className="col-md-2 text-center mb-md-0 mb-5"><div className="partner-img"><img src="assets/a1.png" alt="" /></div></div>
                                <div className="col-md-2 text-center mb-md-0 mb-5"><div className="partner-img"><img src="assets/a2.png" alt="" /></div></div>
                                <div className="col-md-2 text-center mb-md-0 mb-5"><div className="partner-img"><img src="assets/a3.png" alt="" /></div></div>
                              </div>
                            </div>
                      </div>
                    </div>
                  </div>
            </section>
      </div>



            <section className="dex-partner" >
                  <div className="container">
                    <div className="row justify-content-center py-5 my-5">
                      <div className="col-md-5 col-10 mx-auto text-center" data-aos="fade-up">
                            <img src="assets/partner.png" className="w-100" alt="" />
                      </div>
                    </div>
                  </div>
            </section>





            <section className="partners">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-md-10">
                            <div className="partner-card r2" data-aos="fade-up">
                              <h2>Ecosystem Partners</h2>
                              <div className="row justify-content-center align-items-center">
                                <div className="col-md-3 mb-5 text-center px-4"><div className="partner-img"><img src="assets/p1.png" alt="" /></div></div>
                                <div className="col-md-3 mb-5 text-center px-4"><div className="partner-img"><img src="assets/p2.png" alt="" /></div></div>
                                <div className="col-md-3 mb-5 text-center px-4"><div className="partner-img"><img src="assets/p3.png" alt="" /></div></div>
                                <div className="col-md-3 mb-5 text-center px-4"><div className="partner-img"><img src="assets/p4.png" alt="" /></div></div>
                              </div>
                              <div className="row justify-content-center align-items-center">
                                <div className="col-md-3 mb-5 text-center px-4"><div className="partner-img"><img src="assets/p5.png" alt="" /></div></div>
                                <div className="col-md-3 mb-5 text-center px-4"><div className="partner-img"><img src="assets/p6.png" alt="" /></div></div>
                                <div className="col-md-3 mb-md-0 mb-5 text-center px-4"><div className="partner-img"><img src="assets/p7.png" alt="" /></div></div>
                                <div className="col-md-3 mb-md-0 mb-5 text-center px-4"><div className="partner-img"><img src="assets/p8.png" alt="" /></div></div>
                              </div>
                              <div className="row justify-content-center align-items-center">
                                <div className="col-md-3 mb-md-0 mb-5 text-center px-4"><div className="partner-img"><img src="assets/p9.png" alt="" /></div></div>
                                <div className="col-md-3 mb-md-0 mb-5 text-center px-4"><div className="partner-img"><img src="assets/p10.png" alt="" /></div></div>
                                <div className="col-md-3 mb-md-0 mb-5 text-center px-4"><div className="partner-img"><img src="assets/p11.png" alt="" /></div></div>
                                <div className="col-md-3 mb-md-0 mb-5 text-center px-4"><div className="partner-img"><img src="assets/p12.png" alt="" /></div></div>
                              </div>
                            </div>
                      </div>
                    </div>
                  </div>
            </section>


      <section className="faq">
        <div className="container">
          <div className="row justify-content-center py-5 my-lg-5">
            <div className="col-md-10">
              <h2>FAQ</h2>

              <div className="box-faq">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h3 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                      >
                        What is HorizonDEX?
                      </button>
                    </h3>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        HorizonDEX is the first Concentrated Liquidity DEXs
                        built on Linea. Unlike traditional Automated Market
                        Makers, HorizonDEX liquidity pools utilize invested
                        capital with greater efficiency – resulting in greater
                        returns for liquidity providers.
                        <br />
                        <br />
                        HorizonDEX is driven to deliver an unrivalled trading
                        experience with superior DeFi liquidity efficiency –
                        achieving this via its concentrated liquidity protocol
                        and series of affiliated interoperable functional
                        modules.
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h3 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse2"
                        aria-expanded="false"
                        aria-controls="collapse2"
                      >
                        When will the Testnet Phase be over?
                      </button>
                    </h3>
                    <div
                      id="collapse2"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                       
                        All testnet users will receive a testnet NFT.
                        <br />
                        <br />
                        We will announce more details about participation NFTs
                        at the end of the Testnet period. Please follow official
                        HorizonDEX social media platforms for updates and
                        announcements regarding the end of the campaign.
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h3 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse3"
                        aria-expanded="false"
                        aria-controls="collapse3"
                      >
                        Is HorizonDEX audited?
                      </button>
                    </h3>
                    <div
                      id="collapse3"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                      As the HorizonDEX team, we are committed to providing maximum security and transparency.
                       HorizonDEX has been successfully audited by three independent auditing firms, namely: Hacken,
                        SolidProof, and DefiMoon. Our audit results are published in our docs.
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h3 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse4"
                        aria-expanded="false"
                        aria-controls="collapse4"
                      >
                        What is Linea?
                      </button>
                    </h3>
                    <div
                      id="collapse4"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        Linea is a zkRollup solution powered by Consensys' zkEVM
                        technology, an innovative attempt to scale the Ethereum
                        blockchain. The zk in zkRollup and zkEVM stands for
                        "zero-knowledge," a type of proof that allows one party
                        to prove to another that a statement is true without
                        conveying any additional information apart from the fact
                        that the statement is indeed true. This technology
                        significantly enhances privacy and reduces the
                        computational load on the Ethereum network.
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h3 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse5"
                        aria-expanded="false"
                        aria-controls="collapse5"
                      >
                        Is HorizonDEX an official Linea Ecosystem Partner?
                      </button>
                    </h3>
                    <div
                      id="collapse5"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                      Yes, our team has been selected to become an official launch partner for the Linea alpha mainnet release. Additionally, we are an official Linea ecosystem partner.
                       You can find our listing on the official
                       Linea page here: <a href="https://linea.build/apps" target={"_blank"} rel="noreferrer">https://linea.build/apps</a>.
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h3 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse6"
                        aria-expanded="false"
                        aria-controls="collapse6"
                      >
                        What does Concentrated Liquidity mean?
                      </button>
                    </h3>
                    <div
                      id="collapse6"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        Concentrated liquidity is an innovative development in
                        Automated Market Makers (AMMs), enabling Liquidity
                        Providers to adopt more refined strategies, optimize
                        returns and mitigate risk.
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h3 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse7"
                        aria-expanded="false"
                        aria-controls="collapse6"
                      >
                        Do you offer incentives for protocols that list on your DEX?
                      </button>
                    </h3>
                    <div
                      id="collapse7"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                      Yes, we are offering a program for protocols to help them bootstrap their liquidity. 
                      Our team has vast experience in helping protocols achieve high liquidity and setting 
                      the right parameters. Please open a ticket in our Discord and we can offer you a competitive package.
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="partner">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center mb-4">
              <h2 className="d-inline-block">Built on </h2>
            </div>
          </div>
          <div className="partner-grid mt-lg-5">
            <div className="col-01 mb-5">
              <div className="partner-img text-center">
                <img src="assets/linea.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <hr />
    </>
  );
};

export default Home;
